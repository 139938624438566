import React, {
  useContext,
  useEffect,
} from 'react'
import { useNavigate } from 'react-router'
import urljoin from 'url-join'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import Toolbar from '@mui/material/Toolbar'
import LoadingButton from '@mui/lab/LoadingButton'

import { AppContext } from '../../App'
import PageWrapper from './page_wrapper'
import Steps from './steps'
import AccountsTable from './accounts_table'

import api from '../../util/api'
import { FRONTEND_URL } from '../../util/config'

const style = {
  container: {
    minHeight: '90vh',
    width: '100%',
    p: 3,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: {
      lg: 'center',
      sm: 'flex-start',
      xs: 'flex-start'
    },
    alignItems: 'center'
  },
  title: {
    fontWeight: 600,
    width: 400,
  },
  divider: {
    height: '2rem',
    border: 'none'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  flexColumn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  }
}

const aggId = 'finicity'

export default function Accounts() {
  const {
    state,
    dispatch,
    showAlert,
    fetchAccounts,
    showSupport
  } = useContext(AppContext)
  const navigate = useNavigate()

  const handleVisChange = () => {
    if (document.visibilityState === 'visible') {
      fetchAccounts(state.webJWT)
      showAlert('', true)
      setTimeout(() => {
        if (!state.accounts?.length) {
          showSupport(3)
        }
      }, 3000)
      document.removeEventListener('visibilitychange', handleVisChange)
    }
  }

  const getBankLink = async () => {
    dispatch({ type: 'show_loading' })
    try {
      const redirectUri = urljoin(FRONTEND_URL, window.location.pathname)
      const data = {
        consumer: {
          first_name: state.profile.consumer.first_name,
          last_name: state.profile.consumer.last_name
        },
        institution_id: "",
        aggregator_id: aggId,
        redirect_uri: redirectUri,
        oauth: true
      }
      const resp = await api.bankLink(state.webJWT, data)
      if (resp.data.url) {
        dispatch({ type: 'close_loading' })
        showAlert('You are about to go on your bank’s website. After you have connected some accounts, navigate back to this page.\n\nEnsure your device does not block pop-ups.', true)
        document.addEventListener('visibilitychange', handleVisChange)
        setTimeout(() => {
          window.open(resp.data.url, '_blank')
        }, 6000)
        return
      }
      showSupport(3)
      showAlert('There was a problem with your request.')
    }
    catch (e) {
      console.log(e)
      showSupport(3)
      showAlert('There was a problem with your request.')
    }
    finally {
      dispatch({ type: 'close_loading' })
    }
  }

  useEffect(() => {
    if (!state.loginJWT && !state.webJWT) {
      navigate('/login')
    }
  }, [state.loginJWT, state.webJWT, navigate])

  const renderAccounts = () => {
    if (state.accounts?.length) {
      return (
        <Box sx={style.flexColumn}>
          <AccountsTable data={state.accounts} />
          <Divider sx={style.divider} />
          <Box sx={style.flexCenter}>
            <LoadingButton
              variant='customContained'
              onClick={() => {
                if (!state.profile.score) {
                  showAlert('Please complete step one first, to verify your current Vantage Score 4')
                  return
                }
                getBankLink()
              }}
              loading={state.accsLoading}
              sx={{ mx: 1 }}
            >
              Connect More Accounts
            </LoadingButton>
            <Button
              variant='customContained'
              onClick={() => navigate('/vs4')}
              sx={{ mx: 1 }}
            >
              Next Step
            </Button>
          </Box>
        </Box>
      )
    }

    return (
      <>
        <Typography variant='body1' color='text.secondary' fontWeight={600}>
          By clicking “Connect Account” below, you will be directed to select <br />
          your financial institution and enter your login credential.
        </Typography>
        <Divider sx={style.divider} />
        <LoadingButton
          variant='customContained'
          onClick={() => getBankLink()}
          loading={state.accsLoading}
        >
          Connect Account
        </LoadingButton>
      </>
    )
  }

  return (
    <PageWrapper>
      <Box sx={style.container}>
        <Toolbar />
        <Steps />
        <Divider sx={style.divider} />
        <Box width={{ lg: 600, sm: 600, xs: 320 }} sx={style.flexColumn}>
          <Typography
            color='primary'
            fontWeight={600}
            textAlign='center'
            fontSize={{ lg: '1.25rem', xs: '1.1rem' }}
          >
            Find and Connect to Your Bank Accounts
          </Typography>
          <Divider sx={style.divider} />
          {renderAccounts()}
        </Box>
        <Divider sx={style.divider} />
      </Box>
    </PageWrapper>
  )
}
