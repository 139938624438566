import React, { useContext } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button'

import { AppContext } from '../../App'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '.25rem',
  p: 3,
  minWidth: '20rem',
  maxWidth: '24rem',
  whiteSpace: "pre-line",
}

export default function Message({ content, disableOk }) {
  const { dispatch } = useContext(AppContext)
  const handleClose = () => {
    dispatch({ type: 'set', state: 'msgModal', value: null })
  }
  if (!content) return null
  return (
    <Modal
      open={true}
      onClose={handleClose}
      aria-labelledby='modal-alert-title'
      aria-describedby='modal-alert-description'
    >
      <Box sx={style}>
        <Typography variant='text' component='div' sx={{ m: 3 }}>
          {content}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {!disableOk && <Button
            variant='customContainedS'
            size='small'
            // sx={{ width: '4rem' }}
            onClick={handleClose}
          >
            OK
          </Button>}
        </Box>
      </Box>
    </Modal>
  )
}
