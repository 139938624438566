import React, {
  useContext,
  useEffect,
  useState
} from 'react'
import { useNavigate } from 'react-router'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import CircularProgress from '@mui/material/CircularProgress'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import ArrowRightAltRoundedIcon from '@mui/icons-material/ArrowRightAltRounded'

import ConfettiExplosion from 'react-confetti-explosion'

import { AppContext } from '../../App'
import PageWrapper from './page_wrapper'
import Steps from './steps'
import api from '../../util/api'

import scoreCircle from '../../images/score_circle.svg'
import vsLogo from '../../images/vantagescore-logo.svg'

const style = {
  container: {
    minHeight: '90vh',
    width: '100%',
    p: 3,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: {
      lg: 'center',
      sm: 'flex-start',
      xs: 'flex-start'
    },
    alignItems: 'center'
  },
  text: {
    fontWeight: 600,
    textAlign: 'center',
    color: theme => theme.palette.primary.main
  },
  scoreText: {
    fontWeight: 600,
    textAlign: 'center',
    color: theme => theme.palette.primary.main,
  },
  divider: {
    height: '2rem',
    border: 'none'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: { lg: 'row', sm: 'row', xs: 'column' }
  },
  flexColumn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  scoreContainer: {
    height: 300,
    width: 300,
    py: 3,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    bgcolor: '#F6F6F6',
    mx: 2,
    borderRadius: '8px'
  },
  scoreCircle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  scoreCirclePlus: {
    height: 200,
    width: 240,
  },
  arrowsContainer: {
    display: 'flex',
    flexDirection: { lg: 'column', sm: 'column', xs: 'row'},
    alignItems: 'center',
    justifyContent: 'center',
    p: 2,
    my: 2
  },
  arrows: {
    fontSize: '7rem',
    my: -3,
    transform: { lg: 'none', sm: 'none', xs: 'rotate(90deg)'}
  }
}

const VantageScore = ({ text }) => {
  return (
    <Box sx={style.flexCenter}>
      <img src={vsLogo} alt='vs-logo' style={{ height: 20 }} />
      <Typography
        variant='body1'
        color='text.secondary'
        mx={1}
        fontWeight={600}
        dangerouslySetInnerHTML={{ __html: text }}
      >
        {/*{text}*/}
      </Typography>
    </Box>
  )
}

export default function Vs4() {
  const [loading, setLoading] = useState(true)
  const {
    state,
    dispatch,
    showAlert,
    showSupport
  } = useContext(AppContext)
  const navigate = useNavigate()

  const handleCompute = async (webJWT) => {
    setLoading(true)
    try {
      const resp = await api.compute(webJWT)
      if (resp.data?.score) {
        dispatch({
          type: 'set',
          state: 'computeScore',
          value: resp.data['score']
        })
      } else {
        showAlert('We were unable to compute your VantageScore4Plus score. Please contact us at vs4plus@vantagescore.com')
        showSupport(6)
      }
    }
    catch (e) {
      showAlert('We were unable to compute your VantageScore4Plus score. Please contact us at vs4plus@vantagescore.com')
      showSupport(6)
      console.log('Error computing score', e)
    }
    finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!state.loginJWT && !state.webJWT) {
      navigate('/login')
    }
  }, [state.loginJWT, state.webJWT, navigate])

  useEffect(() => {
    handleCompute(state.webJWT)
    // eslint-disable-next-line
  }, [])

  const renderScore = () => {
    if (state.computeScore) {
      return (
        <>
          <Box sx={style.flexCenter}>
            <Box sx={style.scoreContainer}>
              <Typography variant='body1' sx={style.scoreText}>
                Original
              </Typography>
              <Box sx={style.scoreCircle}>
                <img src={scoreCircle} alt='vs4' style={{ height: 175 }} />
                <Typography
                  variant='h5'
                  fontWeight={600}
                  color='text.secondary'
                  sx={{ position: 'relative', top: -95, left: -.5 }}
                  height={0}
                >
                  {Number(state.profile?.score)}
                </Typography>
              </Box>
              <VantageScore text='4.0' />
            </Box>
            <Box sx={style.arrowsContainer}>
              <ArrowRightAltRoundedIcon color='primary' sx={style.arrows} />
              <ConfettiExplosion />
              <ArrowRightAltRoundedIcon color='primary' sx={style.arrows} />
            </Box>
            <Box sx={style.scoreContainer}>
              <Typography variant='body1' sx={style.text}>
                Adjusted
              </Typography>
              <Box sx={style.scoreCircle}>
                <img src={scoreCircle} alt='vs4' style={{ height: 200 }} />
                <Typography
                  variant='h5'
                  fontWeight={600}
                  color='text.secondary'
                  sx={{ position: 'relative', top: -105, left: -.5 }}
                  height={0}
                >
                  {Number(state.computeScore)}
                </Typography>
              </Box>
              <VantageScore text='4<sup>plus</sup>' />
            </Box>
          </Box>
          <Divider sx={style.divider} />
          <Typography variant='h5' sx={style.text}>
            Congratulations!
          </Typography>
          <br />
          <Typography variant='h6' sx={style.text}>
            Your loan is approved based on VantageScore4<sup>plus</sup> credit score.
          </Typography>
          <br /> <br />
          <Typography variant='body1' color='text.secondary' component='div'>
            <p>
            This credit score information is provided solely in the context of a VantageScore4<sup>plus</sup>
            demonstration on the user experience and is for illustrative purposes only.
            This credit score information is intended for the user's information only
            and not for use in connection with any credit-related decision or information
            and should not be relied upon a legal or financial advice.
            </p>
            <p>
            Individuals that have higher credit scores may have a lower VantageScore4<sup>plus</sup> score as it
            has been developed to score up and down to enable positive
            and adverse actions when in production.
            </p>
          </Typography>
          <Divider sx={style.divider} />
        </>
      )
    }

    if (loading) {
      return (
        <>
          <Typography variant='h6' sx={style.text}>
            Sit Back and Relax
          </Typography>
          <Box my={4}>
            <CircularProgress size={50} color='secondary' />
          </Box>
          <Typography variant='h6' sx={style.text}>
            We are unlocking your financial potential
          </Typography>
        </>
      )
    }
    return (
      <>
        <Typography variant='h6' sx={style.text}>
          Your bank account information is still processing.
          Please try connecting more accounts or try again in a few minutes.
          If you continue to have issues, please email vs4plus@vantagescore.
        </Typography>
        <Divider sx={style.divider} />
        <Box sx={{ '& button': { mx: 1 } }}>
          <Button
            variant='customContained'
            onClick={() => navigate('/accounts')}
          >
            Connect More Accounts
          </Button>
          <Button
            variant='customContained'
            onClick={() => handleCompute()}
          >
            Retry
          </Button>
        </Box>
      </>
    )
  }

  return (
    <PageWrapper>
      <Box sx={style.container}>
        <Toolbar />
        <Steps />
        <Divider sx={style.divider} />
        <Box
          width={{ lg: 600, sm: 600, xs: 'auto' }}
          sx={style.flexColumn}
        >
          {renderScore()}
          <Divider sx={style.divider} />
        </Box>
      </Box>
    </PageWrapper>
  )
}
