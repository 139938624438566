/* eslint-disable */
import React, {
  useRef,
  useState,
  useReducer,
  useEffect,
  useContext
} from 'react'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import ContactSupportRoundedIcon from '@mui/icons-material/ContactSupportRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

import { AppContext } from '../../App'

import api from '../../util/api'

const prompts = [
  /*
   * This is the default state, from which there are no transitions to other states.
   * A support ticket should be created only if answers[0] is selected.
   */
  {
    prompt: 'Do you still need help?',
    answers: [
      'No, the issue is resolved',
      'Yes, I need to contact the support team'
    ]
  },
  /*
   * Prompts for Credit page.
   */
  {
    prompt: 'We are sorry you are experiencing difficulties. Please check that your entered the address correctly. Valid choices for the Street Type are: BLVD, ST, RD',
    answers: [
      'OK, I will try again',
      'I did enter everything correctly'
    ]
  },
  {
    prompt: 'Please verify that you correctly typed your SSN',
    answers: [
        'OK, let me try again',
        'I am certain that the SSN is correctly typed'
    ]
  },
  /*
   * Prompts for the bank accounts page.
   */
  {
    prompt: 'What problem are you facing?',
    answers: [
        'My bank page is not shown',
        'I already connected my bank, but it is not shown here',
        'I have a different problem'
    ]
  },
  {
    prompt: 'You need to ensure your device does not block pop-up. Please check in the device/browser settings.',
    answers: [
        'OK, I am going to do that',
        'I have already checked that'
    ]
  },
  {
    prompt: 'On the bank page, you have to select at least one checking and one saving accounts',
    answers: [
        'OK, I will connect again my bank accounts',
        'I have already done that!'
    ],
  },
  /*
   * Prompts for the VS4+ page
   */
  {
    prompt: 'Sorry about that. In the previous step, you must connect at least one checking and one savings account with several recent transactions',
    answers: [
        'OK, I will do that',
        'That is what I have already done!'
    ],
  },
]

const closePrompt = 'Thank you for using our support chat. A support ticket has been submitted.'
const retryPrompt = 'There was a problem submitting your support ticket. Please try again.'

const transitions = {
    0: { 0: null, 1: -1 },
    1: { 0: null, 1: 2 },
    2: { 0: null, 1: 0 },
    3: { 0: 4, 1: 5, 2: 0 },
    4: { 0: null, 1: 0},
    5: { 0: null, 1: 0 },
    6: { 0: null, 1: 0 },
}

const style = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    bottom: 0,
    right: 0,
    zIndex: 999,
    borderRadius: '.25rem',
    m: 2
  },
  chatbox: {
    bgcolor: 'white',
    height: 400,
    width: { lg: 340, sm: 340, xs: 320 },
    boxShadow: 5
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'sticky',
    top: 0,
    borderRadius: '.25rem',
    bgcolor: theme => theme.palette.secondary.main,
    p: 1
  },
  prompt: {
    bgcolor: '#ECECEC',
    borderRadius: '.25rem',
    ml: 0,
    width: 'auto',
    maxWidth: '90%',
    p: 1,
    '& p': {
      color: 'black',
      fontSize: '.8em',
    }
  },
  answer: {
    bgcolor: theme => theme.palette.secondary.main,
    borderRadius: '.25rem',
    width: 'auto',
    maxWidth: '90%',
    ml: 'auto!important',
    mr: '0!important',
    p: 1,
    '& p': {
      color: 'white',
      fontSize: '.8em',
    }
  },
  button: {
    borderRadius: '50px',
    py: 1,
    px: 2,
    fontSize: '.8em',
  },
  stack: {
    p: 1,
    mt: 0,
    maxHeight: 300,
    overflow: 'auto'
  },
  stackFooter: {
    mt: 'auto',
    mb: 0,
    p: 1
  },
}

const initialState = {
  promptsIdx: 0,
  chatHistory: [
    {
      content: prompts[0].prompt,
      style: style.prompt
    }
  ],
}

const createInitialState = () => {
  return {
    ...initialState,
    promptsIdx: 0,
    chatHistory: [
      {
        content: prompts[0].prompt,
        type: 'prompt'
      }
    ]
  }
}

const reducer = (state, action) => {
  const nState = { ...state }
  switch (action.type) {
    case 'set':
      nState[action.state] = action.value
      return nState
    case 'toggle_chat':
      if (action.value) {
        nState.expanded = action.value
      } else {
        nState.expanded = !nState.expanded
      }
      return nState
    case 'answer':
      if (nState.promptsIdx === action.pIdx) {
        return nState
      }
      if (action.pIdx === null) {
        nState.expanded = false
        return nState
      }
      const newAnswer = prompts[state.promptsIdx].answers[action.answer]
      const lastAnswer = state.chatHistory[state.chatHistory.length - 1]
      if (lastAnswer?.content === newAnswer) {
        return nState
      }
      nState.chatHistory.push({
        content: newAnswer,
        type: 'answer'
      })
      return nState
    case 'prompt':
      if (action.pIdx === null) {
        return nState
      }
      const newPrompt = prompts[action.pIdx].prompt
      const lastPrompt = state.chatHistory[state.chatHistory.length - 1]
      if (newPrompt === lastPrompt?.content) {
        return nState
      }
      nState.chatHistory.push({
        content: prompts[action.pIdx].prompt,
        type: 'prompt'
      })
      return nState
    case 'push_chat':
      const msg = action.value
      const lastMsg = state.chatHistory[state.chatHistory.length - 1]
      if (msg.content === lastMsg?.content) {
        return nState
      }
      nState.chatHistory.push(msg)
      return nState
    case 'init_chat':
      nState.promptsIdx = action.pIdx
      nState.chatHistory = [
        {
          content: prompts[action.pIdx].prompt,
          type: 'prompt'
        }
      ]
      return nState
    default:
      return state
  }
}

export default function Support({ initPromptIdx, onClose, setExpanded, expanded, show }) {
  const [chatState, chatDispatch] = useReducer(reducer, createInitialState)
  const { state } = useContext(AppContext)

  const [loading, setLoading] = useState(false)
  const chatRef = useRef(null)

  const scrollToBottom = () => {
    chatRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const toggleExpand = () => setExpanded(!expanded)

  const buildTicketBody = (chat) => {
    try {
      const body = chat.map(c => {
        if (c.type === 'prompt') {
          return `Prompt: ${c.content}`
        } else {
          return `User: ${c.content}`
        }
      })
      body.push(closePrompt)
      return body.join('\n\n')
    }
    catch {
      return 'Support requsted by user.'
    }
  }

  const submitTicket = async () => {
    setLoading(true)
    try {
      const comment = buildTicketBody(chatState.chatHistory)
      const data = { comment }
      const resp = await api.help(state.webJWT, data)
      if (resp.status === 200) {
        chatDispatch({
          type: 'push_chat',
          value: {
            content: closePrompt,
            type: 'prompt'
          }
        })
        return
      }
      chatDispatch({
        type: 'push_chat',
        value: {
          content: retryPrompt,
          type: 'prompt'
        }
      })
    }
    catch(e) {
      console.log('Error contacting support', e)
    }
    finally {
      setLoading(false)
    }
  }

  const handleAnswer = async (idx) => {
    if (loading) return
    const pIdx = transitions[chatState.promptsIdx][idx]
    if (pIdx === null) {
      toggleExpand()
      return
    }
    if (pIdx === -1) {
      await submitTicket()
      chatDispatch({ type: 'set', state: 'promptsIdx', value: pIdx })
      return
    }
    chatDispatch({ type: 'answer', answer: idx, pIdx })
    setLoading(true)
    await new Promise((resolve) => setTimeout(resolve, 1000))
    chatDispatch({ type: 'prompt', pIdx })
    chatDispatch({ type: 'set', state: 'promptsIdx', value: pIdx })
    setLoading(false)
  }

  useEffect(() => {
    scrollToBottom()
  }, [expanded, chatState])

  useEffect(() => {
    chatDispatch({ type: 'init_chat', pIdx: initPromptIdx })
  }, [initPromptIdx])

  const renderAnswers = () => {
    if (chatState.chatHistory[chatState.chatHistory.length - 1]?.content === retryPrompt) {
      return (
        <Stack sx={style.stackFooter} spacing={1}>
          <Button
            variant='contained'
            size='small'
            sx={style.button}
            onClick={submitTicket}
          >
            Retry
          </Button>
        </Stack>
      )
    }
    if (chatState.promptsIdx === -1) {
      return (
        <Stack sx={style.stackFooter} spacing={1}>
          <Button
            variant='contained'
            size='small'
            sx={style.button}
            onClick={onClose}
          >
            Close
          </Button>
        </Stack>
      )
    }

    const prompt = prompts[chatState.promptsIdx]
    if (!prompt) return <></>

    return (
      <Stack sx={style.stackFooter} spacing={1}>
        {prompt.answers.map((answer, idx) => (
          <Button
            key={idx}
            variant='contained'
            size='small'
            sx={style.button}
            onClick={() => handleAnswer(idx)}
          >
            {answer}
          </Button>
        ))}
      </Stack>
    )
  }

  const renderChatHistory = () => {
    if (!chatState.chatHistory?.length) return <></>
    return (
      <Stack sx={style.stack} spacing={1}>
        {chatState.chatHistory.map((chat, idx) => (
          <Box sx={style[chat.type]} key={idx}>
            <Typography>{chat.content}</Typography>
          </Box>
        ))}
        {loading && <Typography fontSize={12}>Typing...</Typography>}
        <div ref={chatRef} sx={{ margin: 0 }}/>
      </Stack>
    )
  }

  if (!show) return <></>

  if (!expanded) {
    return (
      <Box sx={style.container}>
        <Button
          variant='contained'
          startIcon={<ContactSupportRoundedIcon />}
          sx={{ borderRadius: '50px', py: 1, px: 2 }}
          onClick={() => toggleExpand()}
        >
          Chat
        </Button>
      </Box>
    )
  }

  return (
    <Box sx={{ ...style.container, ...style.chatbox }}>
      <Box sx={style.title}>
        <Typography
          variant='h6'
          color='white'
          fontWeight={600}
          sx={{ ml: 1.5 }}
        >
          Chat
        </Typography>
        <IconButton onClick={() => toggleExpand()}>
          <CloseRoundedIcon sx={{ color: 'white' }} />
        </IconButton>
      </Box>
      {renderChatHistory()}
      {renderAnswers()}
    </Box>
  )
}
